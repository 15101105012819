// width and height are there in case we want to use the image as a background image

export const image = {
  balloons: {
    alt: "two hot air balloons",
    width: 202,
    height: 231,
  },
  mountainRange: {
    alt: "snowy mountain range",
    width: 846,
    height: 474,
  },
};
