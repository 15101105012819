export const yulifePink = {
  P100: "#E30D76",
  P50: "#F186BA",
  P40: "#F49DC8",
  P30: "#F7B7D6",
  P20: "#F9CEE4",
  P10: "#FCE7F1",
};

export const uncommonGreen = {
  S1100: "#00ED9D",
  S150: "#80F6CD",
  S140: "#99F8D7",
  S130: "#B1F9E0",
  S120: "#CAF8E8",
  S110: "#E4FCF4",
};

export const rareBlue = {
  S2100: "#00C0F3",
  S250: "#7EDCF5",
  S240: "#94DDF1",
  S230: "#B2ECFB",
  S220: "#CAEFF9",
  S210: "#E3F7FC",
};

export const epicPurple = {
  S3100: "#956AFF",
  S350: "#C7B4FD",
  S340: "#D2C2FD",
  S330: "#DFD3FF",
  S320: "#EAE1FF",
  S310: "#F4F0FF",
};

export const legendaryYellow = {
  S4100: "#FFD600",
  S450: "#FFEB80",
  S440: "#FFEF99",
  S430: "#FFF3B2",
  S420: "#FFF7CC",
  S410: "#FFFBE5",
};

const burgundy = {
  H100: "#640038",
};

const stone = {
  B1100: "#5C5757",
};

const white = {
  white: "#FFFFFF",
};

const pebble = {
  B2100: "#A0A09B",
  B140: "#F4F6F7",
  B240: "#D9D9D7",
  B230: "#E3E3E1",
  B220: "#F5F5F5",
  B210: "#FAFAFE",
};

export const color = {
  yulifePink,
  uncommonGreen,
  rareBlue,
  epicPurple,
  legendaryYellow,
  burgundy,
  stone,
  ...pebble,
  ...yulifePink,
  ...uncommonGreen,
  ...rareBlue,
  ...epicPurple,
  ...legendaryYellow,
  ...burgundy,
  ...stone,
  ...white,
  ...pebble,
  N900: "#464647",
  ER400: "#FC0000",
};

// the new objects above will be used in the brand refresh, and will eventually displace the "colour" object below
export const colour = {
  P900: "#900860",
  P800: "#B60B69",
  P700: "#CC0D6E",
  P600: "#E30D76",
  P500: "#F50D78",
  P400: "#F43E8E",
  P300: "#F664A4",
  P200: "#F791BF",
  P100: "#F9BDD9",
  P50: "#FCE5EF",
  S900: "#113246",
  S800: "#24465E",
  S700: "#325974",
  S600: "#416D8B",
  S500: "#4F7C9D",
  S400: "#688FAE",
  S300: "#80A4C0",
  S200: "#A0BED7",
  S100: "#BEDAEE",
  S50: "#DFF0FF",
  M900: "#353C40",
  M800: "#475157",
  M700: "#56646D",
  M600: "#677984",
  M500: "#748995",
  M400: "#8A9AA5",
  M300: "#A0ADB5",
  M200: "#BDC4C9",
  M100: "#D9DBDD",
  M75: "#E6EAEB",
  M60: "#F4F8F9",
  M50: "#F0F0F0",
  SU300: "#40C057",
  SU200: "#51CF66",
  SU100: "#8CE99A",
  SU50: "#EBFBEE",
  ER300: "#FAB005",
  ER200: "#FFD43B",
  ER100: "#FFE066",
  ER050: "#FFF9DB",
  WA300: "#FA5252",
  WA200: "#FF6B6B",
  WA100: "#FF8787",
  WA50: "#FFF5F5",
  white: "#FFFFFF",
  OV001: "#FFFFFF55",
};

export const ink = {
  inkHeadline: "#640038",
  inkStrong: "#464647",
  inkBase: "#5C5757",
  inkSubtle: "#A0A09B",
  inkNonEssential: "#D9D9D7",
};
