import React from "react";

export const convertNewlinesToBreaks = (str) => {
  if (typeof str !== "string") {
    return str;
  }

  const lines = str.split("\\n");
  return lines.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < lines.length - 1 && <br />}
    </React.Fragment>
  ));
};
