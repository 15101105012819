type CookieName = "hubspotutk" | "NEXT_LOCALE";

export function deleteCookie(name: CookieName) {
  if (typeof window === "undefined") {
    return;
  }

  if (document && typeof document.cookie === "string") {
    if (getCookie(name)) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
    }
  }
}

export function setCookie(name: CookieName, value: string, days: number) {
  if (typeof window === "undefined") {
    return;
  }

  if (document && typeof document.cookie === "string") {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    const expires = "; expires=" + date.toUTCString();

    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
}

export const getCookie = (name: CookieName) => {
  if (typeof window === "undefined") {
    return;
  }

  if (document && typeof document.cookie === "string") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
  }
};
