import { createGlobalStyle } from "styled-components";
import { color } from "./colours";
import { fonts } from "./fonts";

// Fonts should not be imported here, but inside ./static/fonts/fonts.css

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
  }

  *::selection {
    background: ${color.P20};
  }

  body {
    margin: 0;
    overflow-x: hidden;
    font-family: ${fonts.body};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.heading};
    font-style: normal;
    font-weight: normal;
    color: ${color.H100};
  }
  
  h1 {
    font-size: 5.5rem;
    letter-spacing: 0.125rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.5rem;
  }
  
  h6 {
    font-size: 1rem;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0rem;
    padding: 0rem;
  }

  p, label {
    color: ${color.B1100};
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0rem;
  }
  
  span, em, strong, b, i, a {
    font-family: ${fonts.body};
    color: inherit;
  }

  button, input {
    font-family: ${fonts.body};
  }

  q {
    quotes: "“" "”" "‘" "’";
  }
  
  q::before {
    content: open-quote;
  }

  q::after {
    content: close-quote;
  }
`;
