import type { AppProps } from "next/app";
import { GlobalStyle } from "@styles";
import { NextIntlClientProvider } from "next-intl";
import { useRouter } from "next/router";
import { config } from "@utils";
import TagManager from "react-gtm-module";
import * as React from "react";
import initBugsnag from "../services/bugsnag";
import Bugsnag from "@bugsnag/js";
import client from "../urql-client";
import { Provider } from "urql";
import { StyleSheetManager } from "styled-components";

const isBugsnagStarted = initBugsnag();

const NON_FORWARDED_PROPS = [
  "animated",
  "backgroundColor",
  "bold",
  "borderColor",
  "borderRadius",
  "bottom",
  "buttonType",
  "closeTiming",
  "current",
  "firstRow",
  "first3Row",
  "fullScreen",
  "gap",
  "hasTooltip",
  "hideOnMobile",
  "inverted",
  "isClosing",
  "isDisabled",
  "isError",
  "isFirst",
  "isHovering",
  "isInputValid",
  "isLast",
  "isLoading",
  "isMinimised",
  "isMinimized",
  "isOnHeading",
  "isOpen",
  "isScrolled",
  "isSelected",
  "isSwiping",
  "isSubmitSuccessful",
  "isVisible",
  "justifyContent",
  "last",
  "lastRow",
  "left",
  "lineHeight",
  "marginBottom",
  "marginLeft",
  "marginRight",
  "marginTop",
  "maxHeight",
  "maxWidth",
  "mb",
  "ml",
  "mr",
  "mt",
  "navbarType",
  "numberOfSections",
  "paddingBottom",
  "paddingLeft",
  "paddingRight",
  "paddingTop",
  "pb",
  "pl",
  "pr",
  "pt",
  "textPt",
  "reverse",
  "right",
  "tabCount",
  "textAlign",
  "top",
  "transitionSpeed",
  "translateX",
  "windowWidth",
  "windowHeight",
];

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  React.useEffect(() => {
    if (config.GTM_ID) {
      TagManager.initialize({
        gtmId: config.GTM_ID,
        auth: config.GTM_AUTH,
        preview: config.GTM_PREVIEW,
      });
    }

    if (window?.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  if (isBugsnagStarted) {
    const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

    return (
      <ErrorBoundary>
        <GlobalStyle />
        <StyleSheetManager shouldForwardProp={(props) => !NON_FORWARDED_PROPS.includes(props)}>
          <Provider value={client}>
            <NextIntlClientProvider
              locale={router.locale}
              timeZone="Europe/London"
              messages={pageProps.messages}
            >
              <Component {...pageProps} />
            </NextIntlClientProvider>
          </Provider>
        </StyleSheetManager>
      </ErrorBoundary>
    );
  }

  return (
    <>
      <GlobalStyle />
      <StyleSheetManager shouldForwardProp={(props) => !NON_FORWARDED_PROPS.includes(props)}>
        <Provider value={client}>
          <NextIntlClientProvider
            locale={router.locale}
            timeZone="Europe/London"
            messages={pageProps.messages}
          >
            <Component {...pageProps} />
          </NextIntlClientProvider>
        </Provider>
      </StyleSheetManager>
    </>
  );
}
