import { YuLifeLocale } from "hooks";
import { StaticRoutes } from "./StaticRoutes";
import { staticRoutesByLocale as defaultRoutesByLocale } from "./staticRoutesByLocale";
import { ReactElement } from "react";
import { join } from "path-browserify";

const getLanguageForHrefLang = (locale: YuLifeLocale) => {
  switch (locale) {
    case "jp":
      return `jv-${locale}`;
    default:
      return `en-${locale}`;
  }
};

export function createHrefLangs(
  route: string,
  currentLocale: YuLifeLocale,
  staticRoutesByLocale: Record<StaticRoutes, YuLifeLocale[]> = defaultRoutesByLocale,
): ReactElement[] {
  const url =
    process.env.BASE_URL +
    join(currentLocale === "uk" ? "/" : `/${currentLocale}/`, route === "/" ? "" : route);

  const urlWithTrailingSlash =
    url.endsWith("/") || url === process.env.BASE_URL || url.endsWith("3005") ? url : url + "/";

  const locales = staticRoutesByLocale[route];

  if (!locales) {
    return [
      <link key="canonical" rel="canonical" href={urlWithTrailingSlash} />,
      <link
        key={urlWithTrailingSlash}
        rel="alternate"
        href={urlWithTrailingSlash}
        hrefLang={currentLocale === "uk" ? "x-default" : getLanguageForHrefLang(currentLocale)}
      />,
    ];
  }

  return [
    <link key="canonical" rel="canonical" href={urlWithTrailingSlash} />,
    ...locales.map((locale) => {
      const href =
        process.env.BASE_URL + (locale === "uk" ? "" : `/${locale}`) + (route === "/" ? "" : route);
      const hrefWithTrailingSlash =
        href.endsWith("/") || href === process.env.BASE_URL || href.endsWith("3005")
          ? href
          : href + "/";

      return (
        <link
          key={hrefWithTrailingSlash}
          rel="alternate"
          href={hrefWithTrailingSlash}
          hrefLang={locale === "uk" ? "x-default" : getLanguageForHrefLang(locale)}
        />
      );
    }),
  ];
}
