import { Client, cacheExchange, fetchExchange } from "urql";
import { join } from "path-browserify";

const client = new Client({
  url: join(process.env.STRAPI_URL, "graphql"),
  exchanges: [cacheExchange, fetchExchange],
  requestPolicy: "network-only",
  fetchOptions: () => {
    return {
      headers: {
        ...(process.env.YU_CLIENT_TOKEN && { yu_client_token: process.env.YU_CLIENT_TOKEN }),
      },
    };
  },
});

export default client;
