import { useRef, useEffect, useCallback, KeyboardEvent, MouseEvent } from "react";

type CustomEvent = Event | KeyboardEvent | MouseEvent;
type Listener = (event: CustomEvent) => void;

interface UseEventListener {
  type: keyof WindowEventMap;
  listener: Listener;
}

// This hook was inspired by https://tobbelindstrom.com/blog/useEventListener/

export const useEventListener = ({ type, listener }: UseEventListener): void => {
  if (typeof window === "undefined") {
    return;
  }

  const savedListener = useRef<Listener>();

  useEffect(() => {
    savedListener.current = listener;
  }, [listener]);

  const handleEventListener = useCallback((event: CustomEvent) => {
    savedListener.current?.(event);
  }, []);

  useEffect(() => {
    window.addEventListener(type, handleEventListener);
    return () => window.removeEventListener(type, handleEventListener);
  }, [type, handleEventListener]);
};
