import styled from "styled-components";
import { color } from "./colours";
import { fonts } from "./fonts";

export const Article = styled.section`
  padding: 0 2rem;

  @media only screen and (max-width: 30rem) {
    padding: 0 5%;
  }

  max-width: 50rem;
  margin: 0 auto;

  animation: fadein 250ms;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  * {
    scroll-margin-top: 6rem;
  }

  p,
  li {
    position: relative;
    padding: 1rem 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 2rem;
    letter-spacing: 0.075rem;
    color: #5c5757;
    text-decoration: inherit;

    a {
      color: ${color.P100};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  li {
    padding: 0.5rem 0;
  }

  em {
    font-weight: 400;
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  strong em,
  em strong {
    font-weight: bold;
    font-style: italic;
  }

  blockquote {
    font: ${fonts.heading};
    padding: 0;
    border: none;
    float: right;
    width: 300px;
    margin: 12px 0px 12px 36px;
    z-index: 2;

    @media only screen and (max-width: 30rem) {
      float: left;
      margin: 12px 0px 36px 0px;
      width: 100%;
    }

    position: relative;
    top: 5px;
  }

  p:first-child:first-letter {
    float: left;
    font-size: 4rem;
    color: #640038;
    font-family: ${fonts.heading};
    padding: 0.9rem 0.2rem 0 0;
  }

  blockquote,
  blockquote > strong,
  blockquote > p,
  blockquote > p:first-child:first-letter,
  blockquote a {
    padding: 0 0 2px;
    letter-spacing: 0;
    font-family: ${fonts.heading};
    font-size: 32px;
    line-height: 40px;
    color: #956aff;
  }

  blockquote a {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    letter-spacing: 0;
    padding: 2rem 0 1rem 0;
  }

  h1,
  h2,
  h3 {
    color: #640038;
    font-family: ${fonts.heading};
    font-size: 2.5rem;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  figure {
    margin: 0;
  }

  img {
    margin: 1.5rem auto 0;
    padding: 0;
  }

  iframe {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  figcaption {
    margin: -0.25rem 0 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.075rem;
    color: #00c0f3;
    padding: 1rem 1.5rem;
    background: #e3f7fc;
    border-radius: 0 0 0.5rem 0.5rem;

    a {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    list-style: none;
    margin-top: 0;

    @media only screen and (max-width: 65rem) {
      margin-top: 1rem;
    }
  }

  ol {
    counter-reset: custom-counter calc(var(--start) - 1);
  }

  ol li {
    counter-increment: custom-counter;
  }

  ol li::before {
    content: counter(custom-counter);
    color: #e30d76;
    font-weight: bold;

    position: absolute;
    left: -2.5rem;
    top: 0;

    transform: translateY(1rem);
  }

  ul {
    margin-left: 2.5rem;
  }

  ul li::before {
    content: "●";
    color: #e30d76;
    font-size: 0.85rem;

    position: absolute;
    left: -2.25rem;
    top: -0.35rem;

    transform: translateY(0.9rem);
  }

  li > p {
    margin: 0;
    padding: 0;
  }

  li > p:first-child:first-letter {
    float: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 0;
  }

  .footnote-item > p:first-letter {
    float: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    padding: 0;
  }

  img,
  iframe {
    width: 100%;
    height: auto;
  }

  iframe {
    min-height: 26rem;
  }

  // STRAPI OVERRIDES
  strong {
    color: inherit !important;
    font-family: inherit !important;
  }

  a {
    color: #e30d76 !important;
  }
`;
