export const config = {
  API_URL: process.env.API_URL as string,
  STATIC_FOLDER: process.env.STATIC_FOLDER as string,
  CI_JOB_ID: process.env.CI_JOB_ID as string,
  GTM_ID: process.env.GTM_ID as string,
  GTM_AUTH: process.env.GTM_AUTH as string,
  GTM_PREVIEW: process.env.GTM_PREVIEW as string,
  TEAM_URL: process.env.TEAM_URL as string,
  MEMBERS_URL: process.env.MEMBERS_URL as string,
  DISABLE_HUBSPOT_SENDS: Boolean(process.env.DISABLE_HUBSPOT_SENDS === "true"),
  HUBSPOT_AUTH: process.env.HUBSPOT_AUTH as string,
  WEBSITE_ROUTES_KEY: process.env.WEBSITE_ROUTES_KEY as string,
};
