export const isAllowedEmail = (email: string, domainList: string[]) => {
  if (!email) {
    return false;
  }

  if (email.length > 254) {
    return false;
  }

  const domain = email?.match(/@([^\.]*)\./)?.[1];

  if (domainList.includes(domain)) {
    return false;
  }

  return true;
};
