import { keyframes } from "styled-components";

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const localeEarthWiggle = keyframes`
  0% {
    transform: rotate(0) translateY(30px);
  }
  33% {
    transform: rotate(-12deg) translateY(0rem);
  }
  66% {
    transform: rotate(12deg) translateY(0rem);
  }
  100% {
    transform: rotate(0deg) translateY(0rem);
  }
`;

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const localeEarthScaleUp = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  66% {
    transform: rotate(-12deg) scale(1.1);
  }
  100% {
    transform: rotate(-12deg)  scale(1);
  }
`;
