import { usePathname } from "next/navigation";
import { useLocale, YuLifeLocale } from "./useLocale";

export const useHandleLocaleSwitch = () => {
  const pathname = usePathname();
  const { setLocale } = useLocale();

  function handleLocaleSwitch(country: YuLifeLocale) {
    setLocale(country);
    const newPath = pathname.startsWith("/404") ? "/" : pathname;
    const url = process.env.BASE_URL + country === "uk" ? "" : `/${country}` + newPath;
    window.location.href = url;
  }

  return handleLocaleSwitch;
};
