import Bugsnag, { Config } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPluginExpress from "@bugsnag/plugin-express";
import packageJson from "../../package.json";

// This override is used to silence noisy BugSnag logs in local development
const loggerOverride: Config["logger"] = {
  debug() {},
  info() {},
  error() {},
  warn() {},
};

export default function initBugsnag(): boolean {
  if (!process.env.BUGSNAG_KEY || process.env.NEXT_PHASE === "phase-production-build") {
    return false;
  }

  const sharedConfigs: Config & { projectRoot: string } = {
    apiKey: process.env.BUGSNAG_KEY,
    releaseStage: process.env.ENV_NAME,
    appVersion: packageJson.version,
    enabledReleaseStages: ["production", "uat", "development"],
    logger: process.env.ENV_NAME === "local" ? loggerOverride : undefined,
    projectRoot: __dirname,
  };

  // In Next's development mode, we get a warning that Bugsnag.start() was called more than once
  // Checking for the internal _client property fixes this
  if (!(Bugsnag as any)._client) {
    const isClientSide = typeof window !== "undefined";

    if (isClientSide) {
      Bugsnag.start({
        ...sharedConfigs,
        plugins: [new BugsnagPluginReact()],
        appType: "client",
      });
    } else {
      Bugsnag.start({
        ...sharedConfigs,
        plugins: [new BugsnagPluginReact(), BugsnagPluginExpress],
        appType: "server",
      });
    }
  }

  return true;
}
