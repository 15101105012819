export const debounce = <F extends (...args: any[]) => any>(fn: F, time: number) => {
  let timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(fn(...args)), time);
    });
};
