export function generateDefaultSchema({
  canonicalUrl,
  description,
}: {
  canonicalUrl: string;
  description: string;
}): string {
  return `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "YuLife",${
      canonicalUrl
        ? `
    "url": "${canonicalUrl}",`
        : ""
    }
    "logo": "https://d37j6rbzv8y4bc.cloudfront.net/logos/yulife-small-P600.svg?v=447788544",${
      description
        ? `
    "description": "${description ? description.replace(/"/g, `\\"`) : ""}",`
        : ""
    }
    "sameAs": "https://www.linkedin.com/company/yulife",
    "email": "hello@yulife.com",
    "foundingDate": "2016-08-02",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "12 Mallow Street",
      "addressLocality": "London",
      "addressRegion": "London",
      "postalCode": "EC1Y 8RQ",
      "addressCountry": "United Kingdom"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "020 3870 2604",
      "contactType": "Phone"
    },          
    "brand": {
      "@type": "Organization",
      "name": "YuLife"
    }
  }`;
}
