import localFont from "next/font/local";

const JennaSue = localFont({ src: "../public/static/fonts/JennaSue/JennaSue.woff" });
const BerlingskeSerif = localFont({
  src: "../public/static/fonts/BerlingskeSerif/BerlingskeSerif.woff2",
  fallback: ["Georgia", "Times New Roman", "Times", "serif"],
});
const LotaGrotesque = localFont({
  src: [
    { path: "../public/static/fonts/LotaGrotesque/Bold.woff2", weight: "600", style: "normal" },
    {
      path: "../public/static/fonts/LotaGrotesque/SemiBold-Italic.woff2",
      weight: "600",
      style: "italic",
    },
    { path: "../public/static/fonts/LotaGrotesque/Regular.woff2", weight: "400", style: "normal" },
    { path: "../public/static/fonts/LotaGrotesque/Light.woff2", weight: "100", style: "normal" },
    {
      path: "../public/static/fonts/LotaGrotesque/Light-Italic.woff2",
      weight: "100",
      style: "italic",
    },
  ],
  fallback: [
    "--apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Open Sans",
    "Helvetica Neue",
    "sans-serif",
  ],
});

export const fonts = {
  heading: BerlingskeSerif.style.fontFamily,
  body: LotaGrotesque.style.fontFamily,
  speech: JennaSue.style.fontFamily,
};
