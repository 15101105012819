import { GENERIC_CATEGORIES_ERROR, GENERIC_RESOURCES_ERROR } from "./_constants";
import { Logger } from "services/logger";
import { Category } from "pages/resource-hub";
import { ContentType, makeAnchorsOpenNewTab } from "@utils";

export const giveBlockQuotesCurlyQuotationMarks = (str: string): string => {
  return (str || "")
    .replace(`<blockquote>"`, "<blockquote>“")
    .replace(`"</blockquote>`, "”</blockquote>");
};

/**
 * This function converts, for example, <ol start="3"> to <ol style="--start:3">
 * This way, we can access the --start variable within our CSS,
 * which is useful for lists which do not start at 0
 */
export const addStartVariableToOrderedLists = (str: string): string => {
  return (str || "").replace(/<ol start="/g, `<ol style="--start:`);
};

/**
 * This function converts any <br> tag to a div with a height of 2rem
 * This is useful inside a <li> tag, where paragraphs may be separated by a <br>
 * as it allows writers to have multiple paragraphs within a single list item
 */
export const convertBrToDiv = (str: string): string => {
  return (str || "").replace(/<br>/g, '<div style="height:2rem;"></div>');
};

export const getReadMinutesFromHtml = (html: string, wordsPerMinute?: number) => {
  const wordCount = html?.split(" ")?.length || 0;
  const readTime = Math.round(wordCount / (wordsPerMinute || 200)) || 1;
  return `${readTime} min read`;
};

export const formatHtml = (html: string) => {
  html = giveBlockQuotesCurlyQuotationMarks(html);
  html = makeAnchorsOpenNewTab(html);
  html = addStartVariableToOrderedLists(html);
  html = convertBrToDiv(html);
  return html;
};

export const getWatchTimeMinutes = (seconds: number) => {
  const watchTimeMins = Math.round(seconds / 60);
  return `${watchTimeMins} minute watch`;
};

export const formatPublishedDate = (timestamp: string) => {
  const date = Date.parse(timestamp);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const resourceErrorHandler = (categories?: Category[], resources?: []) => {
  if (categories !== undefined && !categories.length) {
    Logger.error(GENERIC_CATEGORIES_ERROR);
    return { error: GENERIC_CATEGORIES_ERROR, notFound: { notFound: true } };
  }

  if (resources != undefined && !resources.length) {
    Logger.error(GENERIC_RESOURCES_ERROR);
    return { error: GENERIC_RESOURCES_ERROR, notFound: { notFound: true } };
  }
};

export const getLinkFromContentType = (
  contentType: string,
  slug?: string,
  category?: string,
): string => {
  let slugPath = slug ? `${slug}/` : "";

  if (category) {
    slugPath = `category/${category}/`;
  }

  switch (contentType) {
    case ContentType.caseStudy:
      return `/case-studies/${slugPath}`;
    case ContentType.newsEvents:
      return `/news-and-events/${slugPath}`;
    case ContentType.blog:
      return `/blog/${slugPath}`;
  }
};
