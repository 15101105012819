// https://tailwindcss.com/docs/customizing-spacing/#default-spacing-scale

export const space = {
  none: "0",
  px: "1px",
  S0: "0",
  S1: "0.25rem",
  S2: "0.5rem",
  S3: "0.75rem",
  S4: "1rem",
  S5: "1.25rem",
  S6: "1.5rem",
  S8: "2rem",
  S10: "2.5rem",
  S12: "3rem",
  S14: "3.5rem",
  S16: "4rem",
  S20: "5rem",
  S24: "6rem",
  S32: "8rem",
  S40: "10rem",
  S48: "12rem",
  S56: "14rem",
  S64: "16rem",
};

export const maxWidth = {
  xxs: "18rem",
  xs: "20rem",
  sm: "24rem",
  md: "28rem",
  lg: "32rem",
  xl: "36rem",
  xl2: "42rem",
  xl3: "48rem",
  xl4: "56rem",
  xl5: "64rem",
  xl6: "72rem",
  full: "100%",
};
