/**
 * @description A logging service, mostly intended for debugging errors in CI/CD pipelines.
 */
class LoggerService {
  loggerKey = "@Logger:";
  silentMode = false;

  constructor(silentMode = false) {
    this.silentMode = silentMode;
  }

  public log(message: string) {
    if (this.silentMode) {
      return;
    }

    console.log(this.loggerKey, message);
  }

  public info(message: string) {
    if (this.silentMode) {
      return;
    }

    console.info(this.loggerKey, message);
  }

  public warn(message: string) {
    if (this.silentMode) {
      return;
    }

    try {
      throw new Error(message);
    } catch (e) {
      console.warn(
        "\x1b[43m",
        this.loggerKey,
        "\x1b[0m",
        "\x1b[33m",
        e.stack.replace("Error: ", "Warning: "),
        "\x1b[0m",
      );
    }
  }

  public error(message: string) {
    if (this.silentMode) {
      return;
    }

    try {
      throw new Error(message);
    } catch (e) {
      console.error("\x1b[41m", this.loggerKey, "\x1b[0m", "\x1b[31m", e.stack, "\x1b[0m");
    }
  }
}

export const Logger = new LoggerService();
