import type { FC } from "react";
import Head from "next/head";
import { usePathname } from "next/navigation";
import { YuLifeLocale } from "hooks";
import { generateDefaultSchema } from "./generateDefaultSchema";
import { createHrefLangs } from "routes/createHrefLangs";
import { StaticRoutes } from "@routes";

export interface MetaImage {
  feature_image?: string;
  og_image?: string;
  twitter_image?: string;
}

interface Metadata {
  description?: string;
  image?: MetaImage | string;
  imageAlt?: string;
  noIndex: boolean;
  title: string;
  url: string;
  schema?: string;
  locale?: YuLifeLocale;
}

export const Meta: FC<Metadata> = ({
  description,
  image,
  imageAlt,
  noIndex,
  title,
  schema,
  locale = "uk",
}) => {
  const path = usePathname();

  const canonicalUrl =
    process.env.BASE_URL + (locale === "uk" ? "" : `/${locale}`) + (path === "/" ? "" : path);

  const canonicalUrlWithTrailingSlash = canonicalUrl.endsWith("/")
    ? canonicalUrl
    : canonicalUrl + "/";

  return (
    <Head>
      {/* Dynamic Types */}
      <title>{title}</title>
      <meta property="og:title" content={title}></meta>
      <meta name="twitter:title" content={title}></meta>

      <meta name="description" content={description}></meta>
      <meta property="og:description" content={description}></meta>
      <meta name="twitter:description" content={description}></meta>

      {typeof image === "string" ? (
        <meta property="og:image" content={image}></meta>
      ) : (
        <meta property="og:image" content={image?.og_image || image?.feature_image}></meta>
      )}
      {typeof image === "string" ? (
        <meta property="twitter:image" content={image}></meta>
      ) : (
        <meta
          property="twitter:image"
          content={image?.twitter_image || image?.feature_image}
        ></meta>
      )}

      {imageAlt ? <meta name="twitter:image:alt" content={imageAlt || "YuLife"}></meta> : null}

      {createHrefLangs((title || "").includes("404") ? StaticRoutes.error : path, locale)}
      <meta property="og:url" content={canonicalUrlWithTrailingSlash}></meta>
      <meta property="twitter:url" content={canonicalUrlWithTrailingSlash}></meta>

      {noIndex ? <meta name="robots" content="noindex"></meta> : null}
      {/* Fixed Types */}
      <meta name="referrer" content="origin"></meta>
      <meta property="og:site-name" content="YuLife"></meta>
      <meta name="author" content="YuLife"></meta>
      <meta property="og:locale" content="en-GB"></meta>
      <meta property="og:type" content="website"></meta>
      <meta name="viewport" content="initial-scale=1.0, width=device-width"></meta>
      <meta name="twitter:site" content="@yulife"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      {/* JSON-LD */}
      <script
        id="json-ld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html:
            schema ||
            generateDefaultSchema({
              canonicalUrl,
              description,
            }),
        }}
      />
    </Head>
  );
};
