import { Meta } from "components/atoms/Meta";
import { getStrapiImageByBreakpoint } from "./blog";
import { BlogDataQuery } from "gql";
import { YuLifeLocale } from "@hooks";

export function getBlogMetaData(
  {
    title,
    featuredImage,
    excerpt,
    metaDescription,
    metaTitle,
    slug,
  }: BlogDataQuery["blogPosts"]["data"][number]["attributes"],
  locale: YuLifeLocale,
  contentType = "article",
) {
  const hasYuLifeSecondaryTitle = /\|\sYuLife/i.test(metaTitle);

  let url: string;

  switch (contentType) {
    case "seo-page":
      url = slug;
      break;
    case "vacancy":
      url = `careers/${slug}`;
      break;
    case "article":
    default:
      url = `blog/${slug}`;
      break;
  }

  const metadata = {
    description: metaDescription || excerpt,
    image: featuredImage.data?.attributes?.formats
      ? getStrapiImageByBreakpoint(featuredImage.data.attributes.formats, "xlarge")
      : undefined,
    imageAlt: metaTitle || title,
    noIndex: false,
    title: hasYuLifeSecondaryTitle ? metaTitle : `${metaTitle || title} | YuLife`,
    url,
  };

  return <Meta locale={locale} {...metadata} />;
}
